import React from "react";
import CommingSoon from "./Pages/CommingSoon";
import '../src/main.scss'
import Footer from "./Layout/Footer";


function App() {
  return (
    <div className="App">
     <CommingSoon/>
    </div>
  );
}

export default App;
